import { creates } from './index';
import { OMUrl,OPUrl } from '@/config/index';

//智慧营销

//获取当前门店banner列表
export function getBannerList(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getBannerList',
    method: 'GET',
    params: params
  })
}

//新增门店banner
export function createBanner(data: object){
  return creates({
    url: OMUrl + '/cs/skin/createBanner',
    method: 'POST',
    data: data
  })
}

//获取banner详情
export function getBannerDetail(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getBannerDetail',
    method: 'GET',
    params: params
  })
}

//编辑保存门店banner
export function editBanner(data: object){
  return creates({
    url: OMUrl + '/cs/skin/editBanner',
    method: 'POST',
    data: data
  })
}

//删除banner
export function delBanner(params: object){
  return creates({
    url: OMUrl + '/cs/skin/delBanner',
    method: 'GET',
    params: params
  })
}

//获取当前品牌详情及套餐列表
export function getBrandSkin(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getBrandSkin',
    method: 'GET',
    params: params
  })
}

//保存套餐
export function saveCompose(data: object){
  return creates({
    url: OMUrl + '/cs/skin/saveCompose',
    method: 'POST',
    data: data
  })
}

//获取次卡活动列表
export function getOnceCardList(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getOnceCardList',
    method: 'GET',
    params: params
  })
}

//获取财升次卡列表
export function getCsCardList(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getCsCardList',
    method: 'GET',
    params: params
  })
}

//新增门店次卡活动
export function createOnceCard(data: object){
  return creates({
    url: OMUrl + '/cs/skin/createOnceCard',
    method: 'POST',
    data: data
  })
}

//获取次卡活动详情
export function getOnceCardDetail(params: object){
  return creates({
    url: OMUrl + '/cs/skin/getOnceCardDetail',
    method: 'GET',
    params: params
  })
}

//编辑门店次卡活动
export function editOnceCard(data: object){
  return creates({
    url: OMUrl + '/cs/skin/editOnceCard',
    method: 'POST',
    data: data
  })
}

//删除门店次卡活动
export function delOnceCard(params: object){
  return creates({
    url: OMUrl + '/cs/skin/delOnceCard',
    method: 'GET',
    params: params
  })
}

//获取活动列表
export function getActivityList(params: object){
  return creates({
    url: OPUrl + '/admin/activity/getActivityList',
    method: 'GET',
    params: params
  })
}

//新增活动
export function addActivity(data: object){
  return creates({
    url: OPUrl + '/admin/activity/addActivity',
    method: 'POST',
    data: data
  })
}

//删除活动
export function delActivity(params: object){
  return creates({
    url: OPUrl + '/admin/activity/delActivity',
    method: 'GET',
    params: params
  })
}

//获取活动详情
export function getActivityDetail(params: object){
  return creates({
    url: OPUrl + '/admin/activity/getActivityDetail',
    method: 'GET',
    params: params
  })
}

//编辑活动
export function editActivity(data: object){
  return creates({
    url: OPUrl + '/admin/activity/editActivity',
    method: 'POST',
    data: data
  })
}

//查询有效的优惠卷列表 财升
export function getCouponList(data: object){
  return creates({
    url: OMUrl + '/api/skin/getCouponList',
    method: 'POST',
    data: data
  })
}

//自定义添加广告位
export function addAdsense(data: object){
  return creates({
    url: OPUrl + '/admin/adsense/addAdsense',
    method: 'POST',
    data: data
  })
}

//获取自定义场所广告位列表
export function getAdsenseList(params: object){
  return creates({
    url: OPUrl + '/admin/adsense/getAdsenseList',
    method: 'GET',
    params: params
  })
}

//自定义修改广告位
export function editAdsense(data: object){
  return creates({
    url: OPUrl + '/admin/adsense/editAdsense',
    method: 'POST',
    data: data
  })
}

//删除自定义广告位
export function delAdsense(data: object){
  return creates({
    url: OPUrl + '/admin/adsense/delAdsense',
    method: 'POST',
    data: data
  })
}

//后台获取企微消息配置详情
export function getConfigDetail(params: object){
  return creates({
    url: OPUrl + '/admin/work/getConfigDetail',
    method: 'GET',
    params: params
  })
}

//后台编辑企微消息文案配置
export function editConfig(data: object){
  return creates({
    url: OPUrl + '/admin/work/editConfig',
    method: 'POST',
    data: data
  })
}

//后台删除图片
export function delImage(data: object){
  return creates({
    url: OPUrl + '/admin/work/delImage',
    method: 'POST',
    data: data
  })
}

//获取分享文案列表
export function getShareList(params: object){
  return creates({
    url: OPUrl + '/admin/share/getShareList',
    method: 'GET',
    params: params
  })
}

//编辑分享好友代付文案图片详情
export function editShareDetail(data: object){
  return creates({
    url: OPUrl + '/admin/share/editShareDetail',
    method: 'POST',
    data: data
  })
}

//获取门店活动列表
export function drawActivityList(data: object){
  return creates({
    url: OPUrl + '/admin/draw/getActivityList',
    method: 'POST',
    data: data
  })
}

//新增活动
export function drawAddDraw(data: object){
  return creates({
    url: OPUrl + '/admin/draw/addDraw',
    method: 'POST',
    data: data
  })
}

//获取活动详情
export function getDrawDetail(params: object){
  return creates({
    url: OPUrl + '/admin/draw/getDrawDetail',
    method: 'GET',
    params: params
  })
}

//编辑活动
export function editDraw(data: object){
  return creates({
    url: OPUrl + '/admin/draw/editDraw',
    method: 'POST',
    data: data
  })
}

//删除活动
export function delDraw(params: object){
  return creates({
    url: OPUrl + '/admin/draw/delDraw',
    method: 'GET',
    params: params
  })
}

//获取活动数据（顶部三个数字）
export function getDrawData(params: object){
  return creates({
    url: OPUrl + '/admin/draw/getDrawData',
    method: 'GET',
    params: params
  })
}

//获取奖品中奖明细
export function getPrizeDetail(params: object){
  return creates({
    url: OPUrl + '/admin/draw/getPrizeDetail',
    method: 'GET',
    params: params
  })
}

//获取活动奖品列表（筛选用户中奖明细使用）
export function getPrizeList(params: object){
  return creates({
    url: OPUrl + '/admin/draw/getPrizeList',
    method: 'GET',
    params: params
  })
}

//获取用户中奖明细
export function getUserDrawList(params: object){
  return creates({
    url: OPUrl + '/admin/draw/getUserDrawList',
    method: 'GET',
    params: params
  })
}